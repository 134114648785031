import React, {useEffect, useState} from 'react'
import FacebookIcon from "../../img/facebook-icon.svg"
import InstagramIcon from "../../img/instagram-icon.svg"
import {FormattedMessage} from "react-intl"
import {NavLink} from "react-router-dom"
import config from '../../config/config'
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { useDispatch } from 'react-redux'
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions"
import { useLocation } from 'react-router-dom'

const Footer = ({lang}) => {
    const location = useLocation()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [legalContent, setLegalContent] = useState('')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [legalPath, setLegalPath] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (legalPath) {
            fetch(legalPath)
                .then(response => {
                    if (response.ok) { // Patikrina, ar atsakymo statusas yra sėkmingas (200-299)
                        return response.text();
                    }
                    throw new Error('Network response was not ok: ' + legalPath);
                })
                .then(result => setLegalContent(result))
                .catch(error => console.error('Fetch error:', error));
        }
    }, [legalPath])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (legalContent) {
            dispatch(setModalSimpleState({
                show: true,
                closeOnClick: true,
                style: {width: '1060px'},
                // header: "action_show_similar",
                body:
                    <div className='conditions-content-page'>
                    <ReactMarkdown children={legalContent} rehypePlugins={[rehypeRaw]} />
                    </div>
            }))
        }
    }, [legalContent, dispatch])

    if (location.pathname === '/play') {
        return null
    }

    const handleLegalClick = (doc) => {
        const termsConfigKey = `${doc}URL_${lang}`;
        if (typeof config[termsConfigKey] === "undefined") {
            return false;
        }

        const termsURL = config[termsConfigKey];
        setLegalPath(termsURL);
    };

    return (
        <footer>
            <div className="footer-line" />
            <div className="footer-content">
                <div className="w-50 d-flex">
                    <a href={"https://www.facebook.com/cgates.lt"}>
                        <img src={FacebookIcon} alt="" className={"me-2"} />
                    </a>
                    <a
                        className={"ms-5"}
                        href={"https://www.instagram.com/cgates_lt/"}
                    >
                        <img src={InstagramIcon} alt="" className={"me-2"} />
                    </a>
                    <a className={"nav-link ms-5"} href={"https://cgates.lt"}>
                        cgates.lt
                    </a>
                </div>
                <div className="w-50 d-flex justify-content-end">
                    <NavLink
                        className={"nav-link ms-5"}
                        to={""}
                        onClick={(event) => {
                            event.preventDefault();
                            handleLegalClick("contacts");
                        }}
                    >
                        <FormattedMessage id="contacts" />
                    </NavLink>
                    <NavLink
                        className={"nav-link ms-5"}
                        to={""}
                        onClick={(event) => {
                            event.preventDefault();
                            handleLegalClick("cookies");
                        }}
                    >
                        <FormattedMessage id="cookies_policy" />
                    </NavLink>
                    <NavLink
                        className={"nav-link ms-5"}
                        to={""}
                        onClick={(event) => {
                            event.preventDefault();
                            handleLegalClick("privacy");
                        }}
                    >
                        <FormattedMessage id="privacy_policy" />
                    </NavLink>
                    <NavLink
                        className={"nav-link ms-5"}
                        to={""}
                        onClick={(event) => {
                            event.preventDefault();
                            handleLegalClick("terms");
                        }}
                    >
                        <FormattedMessage id="terms" />
                    </NavLink>
                </div>
            </div>
            <div className="footer-bottom">© UAB „Cgates“</div>
        </footer>
    );
}

export default Footer
