// import qs from 'qs'

export const recommendedEndpoint = (lang, profileId) => {
    return "recommended/lang/" + lang + "/profile/" + profileId    
}

export const profilesEndpoint = () => {
    return "customer/profiles"    
}

export const userInfoEndpoint = () => {
    return "userinfo"
}

export const avatarsEndpoint = () => {
    return "customer/avatars"    
}

export const genresEndpoint = (lang) => {
    return "genres/lang/" + lang 
}

/**
 * @param lang
 * @returns {string}
 */
export const channelsEndpoint = (lang) => {
    return 'channels?filter[subscribed]=0'
    // return "channels/lang/" + lang + '?filter[subscribed]=0'
}

/**
 * @param page_offset
 * @param page_limit
 * @param lang
 * @param filter_profile
 * @param filter_type
 * @returns {string}
 */
export const bookmarksEndpoint = ({page_offset = '0', page_limit = '100', lang = 'en', filter_profile, filter_type}) => {
    let searchParams = new URLSearchParams();
    if (page_offset) {
        searchParams.set('page[offset]', page_offset);
    }
    if (page_limit) {
        searchParams.set('page[limit]', page_limit);
    }
    if (lang) {
        searchParams.set('lang', lang);
    }
    if (filter_profile) {
        searchParams.set('filter[profile_id]', filter_profile);
    }
    if (filter_type) {
        searchParams.set('filter[type]', filter_type);
    }

    searchParams.set('lang', lang);

    return  'bookmarks?' + searchParams.toString();
}


/**
 * @param channels
 * @param pastDays
 * @param futureDays
 * @param lang
 * @param fromStamp
 * @returns {string}
 */
export const epggridEndpoint = (
    {
        channels,
        pastDays = 5,
        futureDays = 5,
        lang = 'en',
        fromStamp = Math.floor(Date.now() / 1000)
    }) =>
{
    // console.log('epggridEndpoint', channels, pastDays, futureDays, lang)
    const todayStartStamp = Math.floor(fromStamp / (24 * 3600)) * (3600 * 24)
    const from = todayStartStamp - (86400 * pastDays)
    const to = todayStartStamp + 86400 + (86400 * futureDays)

    // console.log('todayStartStamp', todayStartStamp)

    return 'epggrid/channels/' + channels + '/from/' + from + '/to/' + to + '/lang/' + lang
}

/**
 * @param channels
 * @param from
 * @param to
 * @returns {string}
 */
export const spritesEpgEndpoint = (channels, from, to) => {
    return 'channels/' + channels + '/sprites/from/' + from + '/to/' + to
}


export const spritesVodEndpoint = (id) => {
    return 'vodcontent/id/' + id + '/sprites'
}

// export const customChannelsEndpoint = (channelId, lang) => {
//     return "channels/" + channelId + "/lang/" + lang
// }

export const vodcontentEndpoint = (lang, genreId = '', vodId = '') => {
    let url = "vodcontent/lang/" + lang
    if (genreId) {
        url += "/genre/" + genreId
    } else if (vodId) {
        url += "/id/" + vodId
    }

    return url
}

/**
 * @param id
 * @param lang
 * @returns {string}
 */
export const vodAboutEndpoint = ({id, lang}) => {
    return "vod/" + id + "?lang=" + lang
}

export const searchEndpoint = (inputValue, lang, offset, number, suggestions) => {
    // let url = 'search/query/' + inputValue + '/lang/' + lang + '/offset/' + offset + '/number/' + number + '/suggestions/' + suggestions
    let url = 'search-v2/?filter[query]=' + inputValue + '&lang=' + lang + '&page[offset]=' + offset + '&page[limit]=' + number + '&suggestions=' + suggestions

    return url 
}


/**
 *
 * @param id
 * @param page_offset
 * @param page_limit
 * @param lang
 * @param filter_profile
 * @param filter_type
 * @returns {string}
 */
export const smartRowsEndpoint = ({
                                      id,
                                      page_offset = 0,
                                      page_limit = 100,
                                      lang,
                                      filter_profile,
                                      filter_type
}) => {

    let searchParams = new URLSearchParams();
    if (page_offset) {
        searchParams.set('page[offset]', page_offset);
    }
    if (page_limit) {
        searchParams.set('page[limit]', page_limit);
    }
    if (lang) {
        searchParams.set('lang', lang);
    }
    if (filter_profile) {
        searchParams.set('filter[profile_id]', filter_profile);
    }
    if (filter_type) {
        searchParams.set('filter[type]', filter_type);
    }

    searchParams.set('lang', lang);

    return  'smartrows/' + id + '?' + searchParams.toString();
}

/**
 * @param customer_id
 * @param lang
 * @param chid
 * @param vodprovid
 * @param category
 * @returns {string}
 */
export const productGroupsEndpoint = ({customer_id, lang, chid, vodprovid, category}) => {

    let searchParams = new URLSearchParams();
    if (customer_id) {
        searchParams.set('customer_id', customer_id);
    }
    if (lang) {
        searchParams.set('lang', lang);
    }

    if (chid) {
        searchParams.set('filter[chid]', chid);
    }

    if (vodprovid) {
        searchParams.set('filter[' + (category === 'svod' ? 'vodprovid' : 'tvodid') + ']', vodprovid);
    }

    return  'client/smartivus/product-groups?' + searchParams.toString();
}

/**
 * @param customer_id
 * @param lang
 * @returns {string}
 */
export const productItemsEndpoint = ({id, lang}) => {
    let searchParams = new URLSearchParams();
    if (id) {
        searchParams.set('id', id);
    }
    if (lang) {
        searchParams.set('lang', lang);
    }

    return  'client/smartivus/product-items?' + searchParams.toString();
}

/**
 * @param id
 * @param customer_id
 * @param purchase_option
 * @param lang
 * @returns {string}
 */
export const productSubscribeEndpoint = ({id, customer_id, purchase_option, lang}) => {
    let searchParams = new URLSearchParams();
    if (id) {
        searchParams.set('id', id);
    }
    if (customer_id) {
        searchParams.set('customer_id', customer_id);
    }
    if (purchase_option) {
        searchParams.set('purchase_option', purchase_option);
    }
    if (lang) {
        searchParams.set('lang', lang);
    }

    return  'client/smartivus/customer-product/subscribe?' + searchParams.toString();
}

export const statsEndpoint = () => {
    return '/stats'
}

/**
 *
 * @param action
 * @param profile_id
 * @param vod_id
 * @returns {string}
 */
export const wishlistEndpoint = ({action = '', profile_id = '', vod_id = '', series = false}) => {
    let searchParams = new URLSearchParams();

    if (action !== 'post') {
        if (profile_id) {
            searchParams.set('filter[profile_id]', profile_id);
        }
        if (action === 'get') {
            return 'wishlist?' + searchParams.toString();
        }

        //-- action = delete
        return 'wishlist/' + vod_id + '?' + searchParams.toString();
    }

    if (profile_id) {
        searchParams.set('profile_id', profile_id);
    }

    if (vod_id) {
        if (!series) {
            searchParams.set('vod_id', vod_id);
        } else {
            searchParams.set('group_id', vod_id);
        }
    }

    return 'wishlist?' + searchParams.toString();
}

/**
 *
 * @param vod_id
 * @param lang
 * @returns {string}
 */
export const nextEndpoint = ({vod_id = '', lang = ''}) => {
    let searchParams = new URLSearchParams();

    if (lang) {
        searchParams.set('lang', lang);
    }

    return 'vod/' + vod_id + '/next?' + searchParams.toString();
}

/**
 *
 * @param page_offset
 * @param page_limit
 * @param lang
 * @param filter_profile
 * @param filter_channel_id
 * @param filter_epg_id
 * @returns {string}
 */
export const remindersEndpoint = ({
                                      page_offset = '',
                                      page_limit = '',
                                      lang = '',
                                      filter_profile = '',
                                      filter_channel_id = '',
                                      filter_epg_id = ''
}) => {
    if (!filter_profile) {
        return 'reminders'
    }
    // curl --location -g --request GET '{{baseUrl}}/?page[limit]=10&lang=lt,en,ru&page[offset]=0&filter[profile_id]=7923'
    // curl --location -g --request DELETE '{{baseUrl}}/profile/7828?filter[channel_id]=562008&filter[epg_id]=556'

    let searchParams = new URLSearchParams();

    if (page_offset) {
        searchParams.set('page[offset]', page_offset);
    }
    if (page_limit) {
        searchParams.set('page[limit]', page_limit);
    }
    if (lang) {
        searchParams.set('lang', lang);
    }
    if (filter_profile) {
        searchParams.set('filter[profile_id]', filter_profile);
    }
    if (filter_channel_id) {
        searchParams.set('filter[channel_id]', filter_channel_id);
    }
    if (filter_epg_id) {
        searchParams.set('filter[epg_id]', filter_epg_id);
    }

    return 'reminders?' + searchParams.toString();
}
